var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h4', {
    staticClass: "card-title"
  }, [_vm._v(" VERTICAL SLIDERS ")]), _vm._m(0), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "4",
      "md": "6"
    }
  }, [_c('b-card-code', {
    attrs: {
      "title": "Default Vertical Slider"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeVerticalDefault) + " ")];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "d-flex justify-content-center"
  }, [_c('vue-slider', {
    staticClass: "mr-1",
    attrs: {
      "direction": "btt",
      "height": 200,
      "process": false,
      "tooltip": 'none'
    },
    model: {
      value: _vm.value,
      callback: function callback($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  }), _c('vue-slider', {
    staticClass: "mr-1 vue-slider-secondary",
    attrs: {
      "direction": "btt",
      "height": 200,
      "process": false,
      "tooltip": 'none'
    },
    model: {
      value: _vm.value1,
      callback: function callback($$v) {
        _vm.value1 = $$v;
      },
      expression: "value1"
    }
  }), _c('vue-slider', {
    staticClass: "mr-1 vue-slider-success",
    attrs: {
      "direction": "btt",
      "height": 200,
      "process": false,
      "tooltip": 'none'
    },
    model: {
      value: _vm.value2,
      callback: function callback($$v) {
        _vm.value2 = $$v;
      },
      expression: "value2"
    }
  }), _c('vue-slider', {
    staticClass: "mr-1 vue-slider-warning",
    attrs: {
      "direction": "btt",
      "height": 200,
      "process": false,
      "tooltip": 'none'
    },
    model: {
      value: _vm.value3,
      callback: function callback($$v) {
        _vm.value3 = $$v;
      },
      expression: "value3"
    }
  }), _c('vue-slider', {
    staticClass: "mr-1 vue-slider-danger",
    attrs: {
      "direction": "btt",
      "height": 200,
      "process": false,
      "tooltip": 'none'
    },
    model: {
      value: _vm.value4,
      callback: function callback($$v) {
        _vm.value4 = $$v;
      },
      expression: "value4"
    }
  }), _c('vue-slider', {
    staticClass: "mr-1 vue-slider-info",
    attrs: {
      "direction": "btt",
      "height": 200,
      "process": false,
      "tooltip": 'none'
    },
    model: {
      value: _vm.value5,
      callback: function callback($$v) {
        _vm.value5 = $$v;
      },
      expression: "value5"
    }
  })], 1)])], 1), _c('b-col', {
    attrs: {
      "lg": "4",
      "md": "6"
    }
  }, [_c('b-card-code', {
    attrs: {
      "title": "Connect to lower"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeConntectLower) + " ")];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "d-flex justify-content-center"
  }, [_c('vue-slider', {
    staticClass: "mr-1",
    attrs: {
      "direction": "ttb",
      "height": 200,
      "tooltip": 'none'
    },
    model: {
      value: _vm.value6,
      callback: function callback($$v) {
        _vm.value6 = $$v;
      },
      expression: "value6"
    }
  }), _c('vue-slider', {
    staticClass: "mr-1 vue-slider-secondary",
    attrs: {
      "direction": "ttb",
      "height": 200,
      "tooltip": 'none'
    },
    model: {
      value: _vm.value7,
      callback: function callback($$v) {
        _vm.value7 = $$v;
      },
      expression: "value7"
    }
  }), _c('vue-slider', {
    staticClass: "mr-1 vue-slider-success",
    attrs: {
      "direction": "ttb",
      "height": 200,
      "tooltip": 'none'
    },
    model: {
      value: _vm.value8,
      callback: function callback($$v) {
        _vm.value8 = $$v;
      },
      expression: "value8"
    }
  }), _c('vue-slider', {
    staticClass: "mr-1 vue-slider-warning",
    attrs: {
      "direction": "ttb",
      "height": 200,
      "tooltip": 'none'
    },
    model: {
      value: _vm.value9,
      callback: function callback($$v) {
        _vm.value9 = $$v;
      },
      expression: "value9"
    }
  }), _c('vue-slider', {
    staticClass: "mr-1 vue-slider-danger",
    attrs: {
      "direction": "ttb",
      "height": 200,
      "tooltip": 'none'
    },
    model: {
      value: _vm.value10,
      callback: function callback($$v) {
        _vm.value10 = $$v;
      },
      expression: "value10"
    }
  }), _c('vue-slider', {
    staticClass: "mr-1 vue-slider-info",
    attrs: {
      "direction": "ttb",
      "height": 200,
      "tooltip": 'none'
    },
    model: {
      value: _vm.value11,
      callback: function callback($$v) {
        _vm.value11 = $$v;
      },
      expression: "value11"
    }
  })], 1)])], 1), _c('b-col', {
    attrs: {
      "lg": "4",
      "md": "6"
    }
  }, [_c('b-card-code', {
    attrs: {
      "title": "Connect to upper"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeConnectUpper) + " ")];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "d-flex justify-content-center"
  }, [_c('vue-slider', {
    staticClass: "mr-1",
    attrs: {
      "direction": "btt",
      "height": 200,
      "tooltip": 'none'
    },
    model: {
      value: _vm.value12,
      callback: function callback($$v) {
        _vm.value12 = $$v;
      },
      expression: "value12"
    }
  }), _c('vue-slider', {
    staticClass: "mr-1 vue-slider-secondary",
    attrs: {
      "direction": "btt",
      "height": 200,
      "tooltip": 'none'
    },
    model: {
      value: _vm.value13,
      callback: function callback($$v) {
        _vm.value13 = $$v;
      },
      expression: "value13"
    }
  }), _c('vue-slider', {
    staticClass: "mr-1 vue-slider-success",
    attrs: {
      "direction": "btt",
      "height": 200,
      "tooltip": 'none'
    },
    model: {
      value: _vm.value14,
      callback: function callback($$v) {
        _vm.value14 = $$v;
      },
      expression: "value14"
    }
  }), _c('vue-slider', {
    staticClass: "mr-1 vue-slider-warning",
    attrs: {
      "direction": "btt",
      "height": 200,
      "tooltip": 'none'
    },
    model: {
      value: _vm.value15,
      callback: function callback($$v) {
        _vm.value15 = $$v;
      },
      expression: "value15"
    }
  }), _c('vue-slider', {
    staticClass: "mr-1 vue-slider-danger",
    attrs: {
      "direction": "btt",
      "height": 200,
      "tooltip": 'none'
    },
    model: {
      value: _vm.value16,
      callback: function callback($$v) {
        _vm.value16 = $$v;
      },
      expression: "value16"
    }
  }), _c('vue-slider', {
    staticClass: "mr-1 vue-slider-info",
    attrs: {
      "direction": "btt",
      "height": 200,
      "tooltip": 'none'
    },
    model: {
      value: _vm.value17,
      callback: function callback($$v) {
        _vm.value17 = $$v;
      },
      expression: "value17"
    }
  })], 1)])], 1), _c('b-col', {
    attrs: {
      "lg": "4",
      "md": "6"
    }
  }, [_c('b-card-code', {
    attrs: {
      "title": "Tooltips"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeTooltips) + " ")];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "d-flex justify-content-center"
  }, [_c('vue-slider', {
    staticClass: "mr-4",
    attrs: {
      "direction": "btt",
      "tooltip": 'always',
      "height": 200,
      "process": false
    },
    model: {
      value: _vm.value18,
      callback: function callback($$v) {
        _vm.value18 = $$v;
      },
      expression: "value18"
    }
  }), _c('vue-slider', {
    staticClass: "mr-4 vue-slider-success",
    attrs: {
      "direction": "btt",
      "height": 200,
      "process": false,
      "tooltip": 'always'
    },
    model: {
      value: _vm.value19,
      callback: function callback($$v) {
        _vm.value19 = $$v;
      },
      expression: "value19"
    }
  }), _c('vue-slider', {
    staticClass: "mr-4 vue-slider-warning",
    attrs: {
      "direction": "btt",
      "process": false,
      "tooltip": 'always',
      "height": 200
    },
    model: {
      value: _vm.value20,
      callback: function callback($$v) {
        _vm.value20 = $$v;
      },
      expression: "value20"
    }
  })], 1)])], 1), _c('b-col', {
    attrs: {
      "lg": "4",
      "md": "6"
    }
  }, [_c('b-card-code', {
    attrs: {
      "title": "Direction Top To Bottom"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeTopBottom) + " ")];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "d-flex justify-content-center"
  }, [_c('vue-slider', {
    staticClass: "mr-4",
    attrs: {
      "direction": "btt",
      "height": 200,
      "adsorb": true,
      "tooltip": 'none',
      "interval": 20,
      "marks": true
    },
    model: {
      value: _vm.value21,
      callback: function callback($$v) {
        _vm.value21 = $$v;
      },
      expression: "value21"
    }
  }), _c('vue-slider', {
    staticClass: "mr-4 vue-slider-success",
    attrs: {
      "direction": "btt",
      "height": 200,
      "adsorb": true,
      "data": _vm.data,
      "marks": true,
      "tooltip": 'none'
    },
    model: {
      value: _vm.value22,
      callback: function callback($$v) {
        _vm.value22 = $$v;
      },
      expression: "value22"
    }
  }), _c('vue-slider', {
    staticClass: "mr-4 vue-slider-warning",
    attrs: {
      "direction": "btt",
      "height": 200,
      "adsorb": true,
      "included": true,
      "marks": _vm.marks,
      "tooltip": 'none'
    },
    model: {
      value: _vm.value23,
      callback: function callback($$v) {
        _vm.value23 = $$v;
      },
      expression: "value23"
    }
  })], 1)])], 1), _c('b-col', {
    attrs: {
      "lg": "4",
      "md": "6"
    }
  }, [_c('b-card-code', {
    attrs: {
      "title": "Limit"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeLimit) + " ")];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "d-flex justify-content-center"
  }, [_c('vue-slider', {
    staticClass: "mr-1",
    attrs: {
      "direction": "btt",
      "height": 200,
      "min-range": 20,
      "max-range": 50,
      "tooltip": 'none'
    },
    model: {
      value: _vm.value24,
      callback: function callback($$v) {
        _vm.value24 = $$v;
      },
      expression: "value24"
    }
  }), _c('vue-slider', {
    staticClass: "mr-1 vue-slider-secondary",
    attrs: {
      "direction": "btt",
      "height": 200,
      "tooltip": 'none',
      "fixed": true
    },
    model: {
      value: _vm.value25,
      callback: function callback($$v) {
        _vm.value25 = $$v;
      },
      expression: "value25"
    }
  }), _c('vue-slider', {
    staticClass: "mr-1 vue-slider-success",
    attrs: {
      "direction": "btt",
      "height": 200,
      "min-range": 20,
      "max-range": 50,
      "tooltip": 'none'
    },
    model: {
      value: _vm.value26,
      callback: function callback($$v) {
        _vm.value26 = $$v;
      },
      expression: "value26"
    }
  }), _c('vue-slider', {
    staticClass: "mr-1 vue-slider-warning",
    attrs: {
      "direction": "btt",
      "height": 200,
      "tooltip": 'none',
      "fixed": true
    },
    model: {
      value: _vm.value27,
      callback: function callback($$v) {
        _vm.value27 = $$v;
      },
      expression: "value27"
    }
  }), _c('vue-slider', {
    staticClass: "mr-1 vue-slider-danger",
    attrs: {
      "direction": "btt",
      "height": 200,
      "min-range": 20,
      "max-range": 50,
      "tooltip": 'none'
    },
    model: {
      value: _vm.value28,
      callback: function callback($$v) {
        _vm.value28 = $$v;
      },
      expression: "value28"
    }
  }), _c('vue-slider', {
    staticClass: "mr-1 vue-slider-info",
    attrs: {
      "direction": "btt",
      "height": 200,
      "tooltip": 'none',
      "fixed": true
    },
    model: {
      value: _vm.value29,
      callback: function callback($$v) {
        _vm.value29 = $$v;
      },
      expression: "value29"
    }
  })], 1)])], 1)], 1)], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('span', [_vm._v("When the value of direction is ")]), _c('code', [_vm._v("btt")]), _c('span', [_vm._v(" or ")]), _c('code', [_vm._v("ttb")]), _c('span', [_vm._v(", you need to set the component height")])]);
}]

export { render, staticRenderFns }