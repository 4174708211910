var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Disabled Slider"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeDisabled) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("You can disable the entire component with ")]), _c('code', [_vm._v("disabled")]), _c('span', [_vm._v(" or disable the slider separately with ")]), _c('code', [_vm._v("dot-options")])]), _c('vue-slider', {
    staticClass: "mb-2",
    attrs: {
      "disabled": true,
      "direction": _vm.direction
    },
    model: {
      value: _vm.value1,
      callback: function callback($$v) {
        _vm.value1 = $$v;
      },
      expression: "value1"
    }
  }), _c('vue-slider', {
    attrs: {
      "dot-options": _vm.dotOptions,
      "order": false,
      "direction": _vm.direction
    },
    model: {
      value: _vm.value2,
      callback: function callback($$v) {
        _vm.value2 = $$v;
      },
      expression: "value2"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }