var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Adsorb Slider"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeAdsorb) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("When ")]), _c('code', [_vm._v("adsorb")]), _c('span', [_vm._v(" is set to ")]), _c('code', [_vm._v("true")]), _c('span', [_vm._v(", the slider automatically adsorb to the nearest value.")])]), _c('div', [_c('vue-slider', {
    staticClass: "mb-3",
    attrs: {
      "adsorb": true,
      "interval": 10,
      "marks": true,
      "direction": _vm.direction
    },
    model: {
      value: _vm.value1,
      callback: function callback($$v) {
        _vm.value1 = $$v;
      },
      expression: "value1"
    }
  }), _c('vue-slider', {
    staticClass: "mb-3",
    attrs: {
      "adsorb": true,
      "data": _vm.data,
      "marks": true,
      "direction": _vm.direction
    },
    model: {
      value: _vm.value2,
      callback: function callback($$v) {
        _vm.value2 = $$v;
      },
      expression: "value2"
    }
  }), _c('vue-slider', {
    staticClass: "mb-2",
    attrs: {
      "adsorb": true,
      "marks": _vm.marks,
      "included": true,
      "direction": _vm.direction
    },
    model: {
      value: _vm.value3,
      callback: function callback($$v) {
        _vm.value3 = $$v;
      },
      expression: "value3"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }