var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Contained Slider"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeContained) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("When ")]), _c('code', [_vm._v("contained")]), _c('span', [_vm._v(" is set to ")]), _c('code', [_vm._v("true")]), _c('span', [_vm._v(", the edge of the ")]), _c('code', [_vm._v("dot")]), _c('span', [_vm._v(" is used to align.")])]), _c('div', {
    staticClass: "wrap"
  }, [_c('vue-slider', {
    attrs: {
      "direction": _vm.direction
    }
  }), _c('b-card-text', [_vm._v("contained: false")]), _c('vue-slider', {
    attrs: {
      "direction": _vm.direction,
      "contained": true
    }
  }), _c('b-card-text', [_vm._v("contained: true")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }