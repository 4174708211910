var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "RTL Slider"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeRTL) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("Use ")]), _c('code', [_vm._v("direction=\"rtl\"")]), _c('span', [_vm._v(" prop with ")]), _c('code', [_vm._v("<vue-slider>")]), _c('span', [_vm._v(" component for rtl.")])]), _c('vue-slider', {
    attrs: {
      "direction": "rtl"
    },
    model: {
      value: _vm.value,
      callback: function callback($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }