var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Slider behavior"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codebehavior) + " ")];
      },
      proxy: true
    }])
  }, [_c('h6', [_vm._v("Default")]), _c('b-card-text', [_vm._v("It is the default behavior of slider.")]), _c('vue-slider', {
    attrs: {
      "direction": _vm.direction
    },
    model: {
      value: _vm.value,
      callback: function callback($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  }), _c('h6', {
    staticClass: "mt-2"
  }, [_vm._v(" Drag on Click ")]), _c('b-card-text', [_c('span', [_vm._v("When ")]), _c('code', [_vm._v("drag-on-click")]), _c('span', [_vm._v(" is set to ")]), _c('code', [_vm._v("true")]), _c('span', [_vm._v(", can drag the slider directly when pressing the process.")])]), _c('vue-slider', {
    attrs: {
      "direction": _vm.direction,
      "drag-on-click": true
    },
    model: {
      value: _vm.value1,
      callback: function callback($$v) {
        _vm.value1 = $$v;
      },
      expression: "value1"
    }
  }), _c('h6', {
    staticClass: "mt-2"
  }, [_vm._v(" Process not filled ")]), _c('b-card-text', [_c('span', [_vm._v("User ")]), _c('code', [_vm._v("process")]), _c('span', [_vm._v(" prop false for unfill the processbar.")])]), _c('vue-slider', {
    attrs: {
      "direction": _vm.direction,
      "process": false
    },
    model: {
      value: _vm.value2,
      callback: function callback($$v) {
        _vm.value2 = $$v;
      },
      expression: "value2"
    }
  }), _c('h6', {
    staticClass: "mt-2"
  }, [_vm._v(" Min & Max ")]), _c('b-card-text', [_c('span', [_vm._v("Use ")]), _c('code', [_vm._v("min-range")]), _c('span', [_vm._v(" and ")]), _c('code', [_vm._v("max-range")]), _c('span', [_vm._v(" prop to limit the slider.")])]), _c('vue-slider', {
    attrs: {
      "min-range": 10,
      "direction": _vm.direction,
      "max-range": 50
    },
    model: {
      value: _vm.value3,
      callback: function callback($$v) {
        _vm.value3 = $$v;
      },
      expression: "value3"
    }
  }), _c('h6', {
    staticClass: "mt-2"
  }, [_vm._v(" Fixed ")]), _c('b-card-text', [_c('span', [_vm._v("Use ")]), _vm._v("] "), _c('code', [_vm._v("fixed")]), _c('span', [_vm._v(" prop to fixed the slider.")])]), _c('vue-slider', {
    attrs: {
      "direction": _vm.direction,
      "fixed": true
    },
    model: {
      value: _vm.value4,
      callback: function callback($$v) {
        _vm.value4 = $$v;
      },
      expression: "value4"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }